import { Component } from "react";
// import App1 from "./Users";
// import App2 from "./Products"
// import WeatherComponent from "./WeatherComponent";
import Weather from "./Weather";
export default class App extends Component{
  render()
  {
    return(
      <>
       {/* <App1/>
       <App2/> */}


  {/* <WeatherComponent/> */}

  <Weather/>

      </>
    )
  }
}